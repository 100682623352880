<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !_getFishes }">
		<v-preloader v-if="!_getFishes" :message="errorMessage"></v-preloader>

		<div class="container" v-if="_getFishes">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ImplementedManagement', 'TradeManagementMain']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">Журнал учета реализованной рыбы</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<form method="POST">
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Реализация</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<multiselect
															required=""
															v-model="data"
															:options="dataList"
															:preserve-search="true"
															track-by="id"
															label="name"
															placeholder="Выберите разрешение"
															class="multiselect__check"
															name="type"
														>
															<template slot="singleLabel" slot-scope="{ option }">{{
																option.name
															}}</template>
															<template slot="noOptions">{{
																$t('components.permissions_not_found')
															}}</template>
															<template slot="noResult">{{ $t('components.not_found') }}</template>
														</multiselect>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Дата реализации</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div
														class="form__line--input"
														:class="[
															errorMessage.messages && errorMessage.messages.catch_at
																? 'input--error'
																: '',
														]"
													>
														<input
															type="date"
															class="input-linck"
															:max="new Date().toISOString().split('T')[0]"
															required=""
															ref="catch_at"
															name="catch_at"
															value=""
															placeholder="Дата"
														/>
													</div>
													<div
														class="input-required"
														v-if="errorMessage.messages && errorMessage.messages.catch_at"
													>
														<p
															v-for="(massage, massageInd) in errorMessage.messages.catch_at"
															:key="massageInd"
														>
															{{ massage }}
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									<hr class="form__line" />

									<div v-if="data">
										<div v-if="data.id == 0">
											<div class="form__line">
												<div class="form__line--left">
													<div class="form__line--title">Наименование организации</div>
												</div>
												<div class="form__line--right">
													<div class="row">
														<div class="col-xl-5">
															<div
																class="form__line--input"
																:class="[
																	errorMessage.messages && errorMessage.messages.company
																		? 'input--error'
																		: '',
																]"
															>
																<input
																	type="text"
																	class="input-linck"
																	required=""
																	name="company"
																	ref="company"
																	v-model="value.company"
																	value=""
																	:placeholder="$t('headers.name')"
																/>
															</div>
															<div
																class="input-required"
																v-if="errorMessage.messages && errorMessage.messages.company"
															>
																<p
																	v-for="(massage, massageInd) in errorMessage.messages.company"
																	:key="massageInd"
																>
																	{{ massage }}
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div class="form__line">
												<div class="form__line--left">
													<div class="form__line--title">Номер справки о происхождении</div>
												</div>
												<div class="form__line--right">
													<div class="row">
														<div class="col-xl-5">
															<div
																class="form__line--input"
																:class="[
																	errorMessage.messages && errorMessage.messages.reference
																		? 'input--error'
																		: '',
																]"
															>
																<input
																	type="text"
																	class="input-linck"
																	required=""
																	name="reference"
																	ref="reference"
																	v-model="value.reference"
																	value=""
																	placeholder="Номер"
																/>
															</div>
															<div
																class="input-required"
																v-if="errorMessage.messages && errorMessage.messages.reference"
															>
																<p
																	v-for="(massage, massageInd) in errorMessage.messages.reference"
																	:key="massageInd"
																>
																	{{ massage }}
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div v-if="data.id == 1">
											<div class="form__line">
												<div class="form__line--left">
													<div class="form__line--title">Номер справки о происхождении</div>
												</div>
												<div class="form__line--right">
													<div class="row">
														<div class="col-xl-5">
															<div
																class="form__line--input"
																:class="[
																	errorMessage.messages && errorMessage.messages.reference
																		? 'input--error'
																		: '',
																]"
															>
																<input
																	type="text"
																	class="input-linck"
																	required=""
																	name="reference"
																	ref="reference"
																	v-model="value.reference"
																	value=""
																	placeholder="Номер"
																/>
															</div>
															<div
																class="input-required"
																v-if="errorMessage.messages && errorMessage.messages.reference"
															>
																<p
																	v-for="(massage, massageInd) in errorMessage.messages.reference"
																	:key="massageInd"
																>
																	{{ massage }}
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div v-if="data.id == 2">
											<div class="form__line">
												<div class="form__line--left">
													<div class="form__line--title">Страна экспорта</div>
												</div>
												<div class="form__line--right">
													<div class="row">
														<div class="col-xl-5">
															<div
																class="form__line--input"
																:class="[
																	errorMessage.messages && errorMessage.messages.country_id
																		? 'input--error'
																		: '',
																]"
															>
																<input
																	type="text"
																	class="input-linck"
																	required=""
																	name="country_id"
																	ref="country_id"
																	value=""
																	:placeholder="$t('headers.name')"
																/>
															</div>
															<div
																class="input-required"
																v-if="errorMessage.messages && errorMessage.messages.country_id"
															>
																<p
																	v-for="(massage, massageInd) in errorMessage.messages.country_id"
																	:key="massageInd"
																>
																	{{ massage }}
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="form__line">
												<div class="form__line--left">
													<div class="form__line--title">Номер накладной</div>
												</div>
												<div class="form__line--right">
													<div class="row">
														<div class="col-xl-5">
															<div
																class="form__line--input"
																:class="[
																	errorMessage.messages && errorMessage.messages.waybill
																		? 'input--error'
																		: '',
																]"
															>
																<input
																	type="text"
																	class="input-linck"
																	required=""
																	name="certificate_sender"
																	ref="waybill"
																	v-model="value.waybill"
																	value=""
																	placeholder="Номер"
																/>
															</div>
															<div
																class="input-required"
																v-if="errorMessage.messages && errorMessage.messages.waybill"
															>
																<p
																	v-for="(massage, massageInd) in errorMessage.messages.waybill"
																	:key="massageInd"
																>
																	{{ massage }}
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<hr class="form__line" />

										<div class="form__line">
											<div class="form__line--left">
												<div class="form__line--title">
													{{ $t('headers.composition_catch_fish') }}
												</div>
											</div>
											<div class="form__line--right">
												<div class="row">
													<div class="col-xl-7">
														<div
															class="quota-fish__line"
															v-for="(fish, fishIndex) in fildsList"
															:key="fishIndex"
														>
															<div class="form__line--input">
																<div class="row align-items-center">
																	<div class="col-xl-10">
																		<div class="quota-fish__item">
																			<div class="row align-items-center">
																				<div class="col-xl-7">
																					<div class="form__line--input">
																						<input
																							type="text"
																							class="input-linck"
																							:value="fish.fish.name"
																							disabled
																						/>
																						<input
																							type="hidden"
																							:name="'fish[' + fish.fish.id + '][fish_id]'"
																							:ref="'fish'"
																							:data-id="fish.fish.id"
																							:value="fish.fish.id"
																						/>
																					</div>
																				</div>
																				<div class="col-xl-5">
																					<div
																						class="form__line--input"
																						:class="[
																							errorMessage.messages &&
																							errorMessage.messages.fish &&
																							errorMessage.messages.fish[fish.fish.id]
																								? 'input--error'
																								: '',
																						]"
																					>
																						<input
																							type="number"
																							step=".01"
																							min="0"
																							:name="'fish[' + fish.fish.id + '][value]'"
																							:ref="'fish'"
																							class="input-linck"
																							v-model="fish.value"
																							placeholder="0"
																						/>
																					</div>
																					<div
																						class="input-required"
																						v-if="
																							errorMessage.messages && errorMessage.messages.fish
																						"
																					>
																						<p>{{ errorMessage.messages.fish[fish.fish.id] }}</p>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div class="col-xl-2">
																		<div class="input__block--add">
																			<a
																				@click="deleteFildFish(fishIndex)"
																				v-tooltip.right="{
																					content: 'Удалить рыбу',
																					class: ['tooltip__btn'],
																				}"
																			>
																				<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
																			</a>
																		</div>
																	</div>
																</div>
															</div>
														</div>

														<div class="quota-fish__line">
															<div class="form__line--input">
																<div class="row align-items-center">
																	<div class="col-xl-10">
																		<div class="quota-fish__item">
																			<div class="row align-items-center">
																				<div class="col-xl-7">
																					<div class="form__line--input">
																						<multiselect
																							required=""
																							v-model="tradeFish"
																							:options="formList.fish"
																							:preserve-search="true"
																							track-by="id"
																							label="name"
																							placeholder="Выберите рыбу"
																							class="multiselect__check"
																						>
																							<template
																								slot="singleLabel"
																								slot-scope="{ option }"
																								>{{ option.name }}</template
																							>
																							<template slot="noResult">{{
																								$t('components.not_found')
																							}}</template>
																						</multiselect>
																						<!--                                          <input v-if="tradeFish" type="hidden" :name="'fish['+tradeFish.id+'][fish_id]'" :ref="'fish'" :data-id="tradeFish.id" :value="tradeFish.id">-->
																					</div>
																				</div>
																				<div class="col-xl-5">
																					<div class="form__line--input">
																						<input
																							v-if="tradeFish"
																							type="number"
																							step=".01"
																							min="0"
																							class="input-linck"
																							v-model="tradeFishValue"
																							placeholder="0"
																						/>
																						<input
																							v-else
																							type="number"
																							step=".01"
																							min="0"
																							class="input-linck"
																							v-model="tradeFishValue"
																							placeholder="0"
																						/>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div class="col-xl-2">
																		<div class="input__block--add">
																			<a
																				@click="addFildFish()"
																				v-tooltip.right="{
																					content: $t('commands.add_field'),
																					class: ['tooltip__btn'],
																				}"
																			>
																				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM12 8v8M8 12h8" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																			</a>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>

										<div class="form__line">
											<div class="form__line--left"></div>
											<div class="form__line--right">
												<div class="input__row">
													<a class="input-btn form__line--btn" @click="validateFirm()">
														Подписать
													</a>
												</div>

												<div
													class="form__block--line form__messages"
													v-if="message.status"
													:class="{
														'form__messages--error': message.status != 200,
														'form__messages--success': message.status == 200,
													}"
												>
													<label class="form__block--input">
														<span class="form__block--title">
															{{ message.text }}
														</span>
													</label>
												</div>
												<div
													class="form__block--line form__messages"
													v-if="errorMessage.text"
													:class="{
														'form__messages--error': errorMessage.status != 200,
														'form__messages--success': errorMessage.status == 200,
													}"
												>
													<label class="form__block--input">
														<span class="form__block--title">
															{{ errorMessage.text }}
														</span>
													</label>
												</div>
											</div>
										</div>
										<modal
											class="modal__block modal__block--middle modal__block--success"
											name="modal_head"
											:width="550"
											:min-width="550"
											height="auto"
										>
											<div class="modal__block--wrapper">
												<div class="modal__block--close" @click="$modal.hide('modal_head')">
													<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</div>
												<div class="modal__wrapper">
													<div class="modal__header">
														<div class="modal__title">
															Для сохранения журнала, необходимо подтверждение электронно цифровой
															подписью
														</div>
													</div>

													<div class="modal__content">
														<div class="modal__form">
															<div class="block__row">
																<div class="block__row--left">
																	<a class="block__item block__item--green" @click="saveElement">
																		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 100 16.666z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 10l1.667 1.667L12.5 8.333" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																		Подписать ЭЦП
																	</a>
																</div>
																<div class="block__row--right">
																	<a
																		class="block__item block__item--blue"
																		@click="$modal.hide('modal_head')"
																	>
																		{{ $t('button.cancel') }}
																	</a>
																</div>
															</div>
															<div
																class="form__block--line form__messages"
																v-if="message.status"
																:class="{
																	'form__messages--error': message.status != 200,
																	'form__messages--success': message.status == 200,
																}"
															>
																<label class="form__block--input">
																	<span class="form__block--title">
																		{{ message.text }}
																	</span>
																</label>
															</div>
															<div
																class="form__block--line form__messages"
																v-if="errorMessage.text"
																:class="{
																	'form__messages--error': errorMessage.status != 200,
																	'form__messages--success': errorMessage.status == 200,
																}"
															>
																<label class="form__block--input">
																	<span class="form__block--title">
																		{{ errorMessage.text }}
																	</span>
																</label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</modal>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import { api } from '@/boot/axios'

export default {
	components: {
		Multiselect,
	},
	data() {
		return {
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			fildsList: [],
			tradeFish: null,
			tradeFishValue: null,

			value: {
				company: '',
				reference: '',
				waybill: '',
			},
			data: null,
			dataList: [
				{
					id: 0,
					name: 'Оптовая',
					type: 'wholesale',
				},
				{
					id: 1,
					name: 'Розничная',
					type: 'retail',
				},
				{
					id: 2,
					name: 'Экспорт',
					type: 'export',
				},
			],
		}
	},
	computed: {
		...mapGetters(['_getFishes']),
		formList() {
			let list = {
				fish: this._getFishes,
			}
			return list
		},
	},
	methods: {
		...mapActions(['_fetchFishes']),
		addFildFish() {
			let fishTrue = true
			if (!this.tradeFish || this.tradeFish == undefined) fishTrue = false
			if (fishTrue) {
				this.fildsList.find((element) => {
					if (element.fish.id == this.tradeFish.id) fishTrue = false
				})
			}

			if (this.tradeFishValue != 0 && this.tradeFishValue != undefined && fishTrue) {
				this.fildsList.push({
					fish: this.tradeFish,
					value: Number(this.tradeFishValue),
				})

				this.tradeFish = null
				this.tradeFishValue = null
			}
		},
		deleteFildFish(fishIndex) {
			this.fildsList.splice(fishIndex, 1)
		},

		validateFirm() {
			this.errorMessage.status = null
			this.errorMessage.messages = null
			this.errorMessage.text = null

			let valid = false
			let messages = {}

			if (this.$refs.catch_at.value == '') {
				valid = true
				messages.catch_at = [this.$t('system_message.required_message')]
			}
			if (this.data.type == 'wholesale') {
				if (this.$refs.reference.value == '') {
					valid = true
					messages.reference = [this.$t('system_message.required_message')]
				}
				if (this.$refs.company.value == '') {
					valid = true
					messages.company = [this.$t('system_message.required_message')]
				}
			}
			if (this.data.type == 'retail') {
				if (this.$refs.reference.value == '') {
					valid = true
					messages.reference = [this.$t('system_message.required_message')]
				}
			}
			if (this.data.type == 'export') {
				if (this.$refs.country_id.value == '') {
					valid = true
					messages.country_id = [this.$t('system_message.required_message')]
				}
				if (this.$refs.waybill.value == '') {
					valid = true
					messages.waybill = [this.$t('system_message.required_message')]
				}
			}

			messages.fish = []
			this.fildsList.find((element) => {
				if (element.value == '') {
					valid = true
					messages.fish[element.fish.id] = this.$t('system_message.required_message')
				}
			})

			if (valid) {
				this.errorMessage.messages = messages
				this.errorMessage.status = 422
				this.errorMessage.text = this.$t('system_message.form_error')
			} else {
				this.$modal.show('modal_head')
			}
		},

		saveElement() {
			if (this.$configFile.active) return this.onSubmit()
			this.ecp = false
			const websocket = new WebSocket('wss://127.0.0.1:13579/')
			var ready = null
			let ecpData = {
				path: null,
				owner: null,
				data: null,
			}
			websocket.onopen = () => {
				ready = true
				handle()
			}
			websocket.onclose = (e) => {
				if (e.wasClean) {
				} else {
					if (this.$i18n.locale == 'kz')
						alert('NCYLayer бағдарламасы іске қосылғандығына көз жеткізіңіз')
					if (this.$i18n.locale == 'ru') alert('Убедитесь, что программа NCALayer запущена')
					if (this.$i18n.locale == 'en') alert('Make sure that the NCALayer program is running')

					//setErrormodal(true);
					setTimeout(() => {
						//setErrormodal(false);
					}, 5000)
				}
			}
			function handle() {
				if (!ready) {
					alert('Убедитесь, что программа NCALayer запущена')
				} else {
					const data = {
						module: 'kz.gov.pki.knca.commonUtils',
						method: 'signXml',
						args: [
							'PKCS12',
							'SIGNATURE',
							'<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>',
							'',
							'',
						],
					}
					websocket.send(JSON.stringify(data))
				}
			}
			websocket.onmessage = (e) => {
				const data1 = JSON.parse(e.data)
				ecpData.data = data1.responseObject
				this.dataEcp = data1.responseObject
				if (data1.responseObject) {
					this.onSubmit()
					this.$modal.hide('modal_head')
				}
			}
		},

		onSubmit() {
			//evt

			this.message.status = null
			this.message.text = null
			this.message.texts = null

			const formData = new FormData() //evt.target

			formData.append('user_id', this.$store.state.userObject.id)
			formData.append('catch_at', this.$refs.catch_at.value)
			formData.append('type', this.data.type)

			if (this.data.type == 'wholesale') {
				formData.append('reference', this.$refs.reference.value)
				formData.append('company', this.$refs.company.value)
			}
			if (this.data.type == 'retail') {
				formData.append('reference', this.$refs.reference.value)
			}
			if (this.data.type == 'export') {
				formData.append('country_id', this.$refs.country_id.value)
				formData.append('waybill', this.$refs.waybill.value)
			}

			this.fildsList.find((element) => {
				formData.append('fish[' + element.fish.id + '][fish_id]', element.fish.id)
				formData.append('fish[' + element.fish.id + '][value]', element.value)
			})
			if (this.tradeFish && this.tradeFishValue && this.tradeFishValue != undefined) {
				formData.append('fish[' + this.tradeFish.id + '][fish_id]', this.tradeFish.id)
				formData.append('fish[' + this.tradeFish.id + '][value]', this.tradeFishValue)
			}

			formData.append('data', this.dataEcp || this.$configFile.xml)

			api
				.post('implemented/create', formData)
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message
						this.$router.push({
							path: '/' + this.$i18n.locale + '/account/logs/implemented/' + response.data.data.id,
						})
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					this.errorMessage.messages = error.response.data.error_messages
				})
		},
	},
	beforeMount() {
		this._fetchFishes()
		this.data = {
			id: 0,
			name: 'Оптовая',
			type: 'wholesale',
		}
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
}
</script>

<style scoped></style>
